import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';


const SmartReaderApprovedStatus = ({smartReader}) => {
    let tooltipTitle
    let iconComponent
    if (!smartReader.activated) {
        tooltipTitle = "Pending Approval"
        iconComponent = <ErrorIcon style={{color: "darkorange"}}/>
    } else if (!smartReader.provisioning_cfg_synced || !smartReader.provisioning_meter_id_synced) {
        tooltipTitle = "Sync in progress"
        iconComponent = <SyncIcon style={{color: "blue"}}/>
    } else if (smartReader.provisioning_cfg_error_code !== 0 || smartReader.provisioning_meter_id_error_code) {
        tooltipTitle = `Sync with errors. Error code: ${smartReader.provisioning_cfg_error_code || smartReader.provisioning_meter_id_error_code}`
        iconComponent = <SyncProblemIcon style={{color: "red"}}/>
    } else {
        tooltipTitle = "Synced successfully"
        iconComponent = <CheckCircleIcon style={{color: "green"}}/>
    }


    return (
        <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{timeout: 400}}
            title={tooltipTitle}
            placement="top"
        >
            {iconComponent}
        </Tooltip>
    )
}

export default SmartReaderApprovedStatus
