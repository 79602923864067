import {Typography} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";

import styles from './LayoutStyles.module.css';
import {useQuery} from "@tanstack/react-query";
import {API_URLS, QUERY_KEYS} from "../../consts/consts";
import {callApiV2} from "../../services/server_apiV2";

export const Footer = () => {
    const {t} = useTranslation()
    const [version, setVersion] = useState('Loading...');

    const BEVersionQuery = useQuery({
        queryKey: [QUERY_KEYS.BE_VERSION],
        queryFn: async () =>
            await callApiV2({url: API_URLS.BE_VERSION}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
    });
    const FEVersion = process.env.REACT_APP_VERSION || 'unknown';

    return (
        <div className={styles.footerContainer}>
            <Typography
                style={{flexGrow: 1, textAlign: "center", color: '#909090'}}
            >
                {t('Copyright © 2024 | All Rights Reserved. MarchlMetering is a registered trademark of Marchl Metering Software.')}
            </Typography>

            <div className={styles.versions}>
                <div style={{color: '#636262'}}>BE_Ver: {BEVersionQuery.data?.version}</div>
                <div style={{color: '#636262'}}>FE_Ver: {FEVersion}</div>
            </div>
        </div>
    );
};
