export let BASE_URL = '';
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    BASE_URL = 'https://api.marchl-metering.com/';  //hetzner prod
} else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    BASE_URL = 'https://api-dev.marchl-metering.com/';  //hetzner dev
} else {
    BASE_URL = 'http://localhost:8000/';
}

const API_URL = BASE_URL + 'api/';

const getApplicationsUrl = (gatewayId) => API_URL + `gateways/${gatewayId}/applications/`;
const getGatewayLinkingUrl = (gatewayId) => API_URL + `gateways/${gatewayId}/link/`;
const getSmartReaderLinkUrl = (readerEui) => API_URL + `smart_readers/${readerEui}/link/`;

export const API_URLS = {
    ORGANIZATIONS: API_URL + 'organizations/',
    APPLICATIONS: getApplicationsUrl,
    PROFILES: API_URL + `meters/device-profiles/`,

    GATEWAYS: API_URL + 'gateways/',
    GATEWAYS_LINKING: getGatewayLinkingUrl,

    READERS_LINK: getSmartReaderLinkUrl,
    READERS_COMPLEX_LINK: API_URL + 'smart_readers/complex_link/',
    METERING: API_URL + 'metering/periodic_data/',

    SMART_READERS: API_URL + `smart_readers/`,
    SMART_READERS_PHOTO: API_URL + `smart_reader_photo_proofs/`,

    USERS: API_URL + 'users/',
    BE_VERSION: API_URL + 'version/',

    GATEWAY_FIRMWARE_VERSIONS: API_URL + 'ota-versions/gateway/',
    SR_FIRMWARE_VERSIONS: API_URL + 'ota-versions/smart_reader/',
    NOTIFICATIONS: API_URL + 'notifications/'
};

export const AUTH_TOKEN_URL = {
    RETREIVE: API_URL + 'token/',
    REFRESH: API_URL + 'token/refresh/',
    VERIFY: API_URL + 'token/verify/'
};


export const QUERY_KEYS = {
    ORGANIZATIONS: 'organizations',
    APPLICATIONS: 'applications',

    GATEWAYS_UNLINKED: 'gateways_unlinked',
    GATEWAYS_LINKED: 'gateways_linked',
    GATEWAYS_STATES: 'gateways_states',

    PROFILES: 'profiles',
    SMART_READERS_UNLINKED: 'smart_readers_unlinked',
    SMART_READERS_LINKED: 'smart_readers_linked',
    SMART_READERS_PHOTO: 'smart_readers_photo',

    METERING: 'meter_measurements',
    STATE: 'state',

    USERS_WITHOUT_ORG: 'users_without org',
    USERS_ME: 'users_me',
    BE_VERSION: 'BE_version',

    GATEWAY_FIRMWARE_VERSIONS: 'gateway_firmware_versions',
    SR_FIRMWARE_VERSIONS: 'sr_firmware_versions',
    NOTIFICATIONS: 'notifications',
};

export const DEFAULT_GW_QUERY_OPTIONS = {is_linked: 'True'}
export const DEFAULT_SMART_READER_QUERY_OPTIONS = {is_linked_to_gw: 'True'}

export const SMART_READER_TYPES = [
    {label: 'Electricity'},
    {label: 'Water'},
    {label: 'Gas'}
];

export const CONNECTION_STATUS_CHOICES = {
    NEVER_SEEN: "never_seen",
    ONLINE: "online",
    OFFLINE: "offline",
}

// label: value
export const USER_ROLES_OPTIONS = {
    "Superuser": "superuser",
    "Manufacturer": "manufacturer",
    "Warehouse Manager": "wm",
    "Helpdesk": "helpdesk",
    "Utility Admin": "utility_admin",
    "Utility Tech": "utility_tech",
}